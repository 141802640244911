import React from 'react'
import { Container, Row, Col } from "react-bootstrap";

function getYear() {
    const now = Date.now();
    const date = new Date(now);
    return date.getFullYear();
  }

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col className='text-center py-3'>
                        &copy; {getYear()} by Derya Uysal.
                    </Col>
                </Row>
            </Container>            
        </footer>
    )
}

export default Footer
