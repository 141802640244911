
import React from 'react'
import { Link } from "gatsby"
import { Navbar, Nav, Container, Button } from 'react-bootstrap';



export default function Header(props) {
  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <Link to='/'>
            <h1>
              <Navbar.Brand>Derya Uysal</Navbar.Brand>
            </h1>
          </Link>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <Link to="/" className="nav-link">
                Home
              </Link>
              <Link to="/research" className="nav-link">
                Research
              </Link>
              <Link to='/teaching' className="nav-link">
                Teaching
              </Link>
              <Link to='/software' className="nav-link">
                Software
              </Link>
              <Button href='/resume' variant='secondary rounded'>
                CV
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}
