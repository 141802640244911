import React from "react"
import Header from "./header"
import Footer from "./footer";
import { Container } from 'react-bootstrap';



export default function Layout({ children }) {
  return (
    <>
      <Header></Header>
      <main className="py-3">
        <Container>
          {children}
        </Container>
      </main>
      <Footer></Footer>
    </>
  )
}